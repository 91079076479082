import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/system";
import { Slide } from "react-awesome-reveal";
import serviceImage1 from "../../assets/images/bomba.png";
import serviceImage2 from "../../assets/images/maquina.jpg";
import serviceImage3 from "../../assets/images/consultoria1170-1416.jpg";

const HoverCard = styled(Card)({
  borderRadius: 8, // Adicionado border-radius para bordas arredondadas
  "&:hover": {
    transform: "scale(1.17)",
    transition: "transform 0.3s",
  },
});

const Services = () => {
  const services = [
    {
      name: "Venda de Peças Novas para Veículos Automotores:",
      description:
        "Oferecemos uma ampla gama de peças novas para diversos tipos de veículos.",
      image: serviceImage1, 
    },
    {
      name: "Reparação de Bombas Diesel:",
      description:
        "Especializamo-nos na reparação de bombas diesel, incluindo a manutenção, reparo e substituição de bombas diesel em vários tipos de veículos.",
      image: serviceImage2, 
    },
    {
      name: "Consultoria e Suporte ao Cliente:",
      description:
        "Oferecemos consultoria e suporte ao cliente para ajudar os clientes a escolher as peças certas para seus veículos e a entender como manter e reparar suas bombas diesel.",
      image: serviceImage3,
    },
  ];

  const directions = ["left", "up", "right"];

  return (
    <Box id="servicos">
      <Box
        sx={{
          width: "100%",
          height: "auto",
          position: "relative",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ py: { xs: 5, md: 10 }, mb: { xs: 5, md: 10 } }}
        >
          <Box
            sx={{
              textAlign: "center",
              height: "auto",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              padding: { xs: 2, md: 4 }, // Adapte o espaçamento interno para diferentes tamanhos de tela
              borderRadius: 8,
            }}
          >
            <Box sx={{ textAlign: "center", mb: 15 }}>
              <Typography variant="h3" component="h2" gutterBottom>
                Nossos Serviços
              </Typography>
              <Typography variant="subtitle1" component="p">
                Conheça mais sobre os serviços que oferecemos.
              </Typography>
            </Box>
            <Grid container spacing={8}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Slide direction={directions[index] as "left" | "up" | "right"}>
                    <HoverCard elevation={3}>
                      <CardMedia
                        component="img"
                        sx={{
                          height: { xs: 300, md: 400 },
                          objectFit: "cover",
                        }}
                        image={service.image}
                        alt={`Imagem do ${service.name}`}
                      />
                     <CardContent sx={{ height: 300 }}>
                    <Typography variant="h6" component="h3" sx={{ fontSize: "1.2em", mb: 1 }}>
                      {service.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{ fontSize: "1rem"  }}
                    >
                      {service.description}
                    </Typography>
                  </CardContent>
                    </HoverCard>
                  </Slide>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Services;
