import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/system";
import { Slide } from "react-awesome-reveal";
import projectImage1 from "../../assets/images/atendimento.jpg";
import projectImage2 from "../../assets/images/online.jpg";
import projectImage3 from "../../assets/images/ecologico.jpg";

const HoverCard = styled(Card)({
  "&:hover": {
    transform: "scale(1.17)",
    transition: "transform 0.3s",
  },
  height: "100%",
});

type Project = {
  name: string;
  description: string;
  image: string;
};

const Projects = () => {
  const projects: Project[] = [
    {
      name: "Modernização do Atendimento ao Cliente:",
      description:
        "Objetivo: Aprimorar a experiência do cliente e otimizar o suporte.\nEtapas:\n- Implementação de um sistema de atendimento ao cliente baseado em tickets.\n- Treinamento da equipe para lidar com consultas e reclamações de forma eficiente.\n- Desenvolvimento de um portal de autoatendimento para os clientes.",
      image: projectImage1,
    },
    {
      name: "Expansão Online:",
      description:
        "Objetivo: Ampliar a presença da empresa no mercado digital.\nEtapas:\n- Criação de um site responsivo e informativo.\n- Desenvolvimento de estratégias de marketing digital (SEO, mídia social, e-mail marketing).\n- Lançamento de uma loja virtual para venda de peças e acessórios.\n- Monitoramento das métricas de desempenho online.",
      image: projectImage2,
    },
    {
      name: "Desenvolvimento de Produtos Sustentáveis:",
      description:
        "Objetivo: Introduzir produtos ecologicamente corretos no catálogo da empresa.\nEtapas:\n- Pesquisa de mercado para identificar demandas por produtos sustentáveis.\n- Parceria com fornecedores comprometidos com práticas sustentáveis.\n-baixo impacto ambiental.",
      image: projectImage3,
    },
  ];

  const directions = ["left", "up", "right"];

  return (
    <Box id="projetos" sx={{ bgcolor: "#444", color: "#fff", py: 5 }}>
      <Container
        maxWidth="lg"
        sx={{ py: { xs: 5, md: 10 }, mb: { xs: 5, md: 10 } }}
      >
        <Box sx={{ textAlign: "center", mb: 15 }}>
          <Typography variant="h3" component="h2" gutterBottom>
            Nossos Projetos
          </Typography>
          <Typography variant="subtitle1" component="p">
            Conheça mais sobre nossos projetos.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {projects.map((project, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Slide direction={directions[index] as "left" | "up" | "right"}>
                <HoverCard elevation={3}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: { xs: 300, md: 400 },
                      objectFit: "cover",
                    }}
                    alt={`Projeto ${index + 1}`}
                    src={project.image}
                  />
                  <CardContent sx={{ height: 300 }}>
                    <Typography variant="h6" component="h3" sx={{ fontSize: "1.2em", mb: 1 }}>
                      {project.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{ fontSize: "1rem"  }}
                    >
                      {project.description}
                    </Typography>
                  </CardContent>
                </HoverCard>
              </Slide>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Projects;
