import React, { useState, useEffect, useRef } from "react";
import { Slider, IconButton, Box } from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import video from '../../assets/video/institucional.mp4'
import "./style.css";

const VideoComponent: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(0.5);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
      videoRef.current.muted = muted;
    }
  }, [volume, muted]);

  return (
    <div className="chev-down">
      <div className="video">
        <video ref={videoRef} autoPlay loop>
          <source
            src={video}
            type="video/mp4"
          />
          Seu navegador não suporta o elemento de vídeo.
        </video>
        {/* Container para os controles com largura máxima de 1200px */}
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "1200px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            px: 2, // padding horizontal
            alignItems: "center", // Alinha os itens verticalmente
          }}
        >
          {/* Controle de Volume */}
          <Slider
            aria-label="Volume"
            value={volume}
            onChange={(e, newValue) => setVolume(newValue as number)}
            min={0}
            max={1}
            step={0.01}
            sx={{width: { xs: 50, md: 100 }, 
            color: "white" }}
          />
          {/* Ícone de seta para baixo indicando a rolagem da página */}
          <IconButton
            sx={{
              animation: "float 1.2s ease-in-out infinite",
              color: "white",
              fontSize: "50px",
            }}
          >
            <KeyboardArrowDownIcon sx={{ fontSize: 60 }} />
          </IconButton>
          {/* Botão de Mute/Unmute */}
          <IconButton
            onClick={() => setMuted(!muted)}
            color="primary"
            sx={{ color: "white" }}
          >
            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
        </Box>
      </div>
    </div>
  );
};

export default VideoComponent;
