import React, { useState } from "react";
import { Link } from "react-scroll";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from '../../assets/images/logoribeira.png';

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <List>
        <ListItem button>
          <Link to="Início" smooth={true} duration={1000} onClick={closeDrawer}>
            <ListItemText primary="Início" />
          </Link>
        </ListItem>
        <ListItem button>
          <Link to="quemsomos" smooth={true} duration={1000} onClick={closeDrawer}>
            <ListItemText primary="Quem somos" />
          </Link>
        </ListItem>
        <ListItem button>
          <Link to="projetos" smooth={true} duration={1000} onClick={closeDrawer}>
            <ListItemText primary="Projetos" />
          </Link>
        </ListItem>
        <ListItem button>
          <Link to="servicos" smooth={true} duration={1000} onClick={closeDrawer}>
            <ListItemText primary="Serviços" />
          </Link>
        </ListItem>
        <ListItem button>
          <Link to="atendimento" smooth={true} duration={1000} onClick={closeDrawer}>
            <ListItemText primary="Atendimento" />
          </Link>
        </ListItem>
        <ListItem button>
          <Link to="contato" smooth={true} duration={1000} onClick={closeDrawer}>
            <ListItemText primary="Contato" />
          </Link>
        </ListItem>
        <ListItem button>
          <Link to="localizacao" smooth={true} duration={1000} onClick={closeDrawer}>
            <ListItemText primary="Localização" />
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box id="Início">
      <AppBar position="static" sx={{ backgroundColor: "#444" }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-start" }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "6px 40px",
                }}
              >
                <Link to="Início" smooth={true} duration={1000} onClick={closeDrawer}>
                  <img src={Logo} alt="Logo" style={{ width: '120px', height: 'auto' }} />
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 2,
                display: { xs: "none", sm: "flex" },
                justifyContent: "space-around",
              }}
            >
              <Link to="Início" smooth={true} duration={1000}>
                <Button color="inherit" sx={{ "&:hover": { backgroundColor: "orange" } }}>Início</Button>
              </Link>
              <Link to="quemsomos" smooth={true} duration={1000}>
                <Button color="inherit" sx={{ "&:hover": { backgroundColor: "orange" } }}>Quem somos</Button>
              </Link>
              <Link to="projetos" smooth={true} duration={1000}>
                <Button color="inherit" sx={{ "&:hover": { backgroundColor: "orange" } }}>Projetos</Button>
              </Link>
              <Link to="servicos" smooth={true} duration={1000}>
                <Button color="inherit" sx={{ "&:hover": { backgroundColor: "orange" } }}>Serviços</Button>
              </Link>
              <Link to="atendimento" smooth={true} duration={1000}>
                <Button color="inherit" sx={{ "&:hover": { backgroundColor: "orange" } }}>Atendimento</Button>
              </Link>
              <Link to="contato" smooth={true} duration={1000}>
                <Button color="inherit" sx={{ "&:hover": { backgroundColor: "orange" } }}>Contato</Button>
              </Link>
              <Link to="localizacao" smooth={true} duration={1000}>
                <Button color="inherit" sx={{ "&:hover": { backgroundColor: "orange" } }}>Localização</Button>
              </Link>
            </Box>
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
            >
              {/* Adicione mais itens conforme necessário */}
            </Box>
          </Toolbar>
        </Container>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
        >
          {drawer}
        </Drawer>
      </AppBar>
    </Box>
  );
}

export default Header;
